.navbar {
    background-color: #1f2937;
    padding: 1rem;
}

.navbar a {
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    text-decoration: none;
}

.navbar a:hover {
    text-decoration: underline;
}

.navbar .search-bar {
    margin-left: auto;
}

.search-bar input {
    border-radius: .5rem;
    border: 1px solid #d1d5db;
    transition: border-color 0.3s;
}

.search-bar input:focus {
    border-color: none;
    outline: none;
}
  